/**
 * 传入一个数组，返回一个多维数组
 */

export function handleSwiperData(data, group) {
  const result = [];
  const len = data.length;
  const loop = Math.ceil(len / group);
  for (let i = 0; i < loop; i++) {
    result.push(data.slice(i * group, (i + 1) * group));
  }
  return result;
}