import { useState } from 'react';
import { Form, Input, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './supplier-apply.scss';

function SupplierApply() {
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const stepIcon1 = require('@/assets/icons/step-prev.png');
  const stepIcon2 = require('@/assets/icons/step-next.png');
  const stepIcon3 = require('@/assets/icons/step-finish-icon.png');


  const nextStepClick = () => {
    form.validateFields().then(values => {
      console.log('第一个表单数据：', values);
      setFormValues(values);
      setStep(2);
      // 页面滚动到顶部
      window.scrollTo(0, 0);
    }).catch(error => {
      console.log(error);
    });
  }

  const prevStepClick = () => {
    form.setFieldsValue(formValues);
    setStep(1);
    // 页面滚动到顶部
    window.scrollTo(0, 0);
  }

  const confirmClick = () => {
    form2.validateFields().then(values => {
      let data = {
        ...values,
        ...formValues
      }
      console.log(data); // 要提交的数据
      setStep(3);
      setFormValues({});
      form.resetFields();
      form2.resetFields();
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <div className="supplier-apply">
      <div className='content-width'>
        <div className='apply-content'>
          <div className='tt'>创建您的供应商账户</div>
          <div className='stt'>欢迎来到EightDish八大碗，请按一下步骤完成注册。</div>
          <div className='steps-wrap'>
            <div className={step === 1 ? 'step step-active' : 'step'}>
              <div className='icon'>1</div>
              <div className='text'>填写企业信息</div>
            </div>
            <div className={step === 2 ? 'step step-active' : 'step'}>
              <div className='icon'>2</div>
              <div className='text'>填写联系人信息</div>
            </div>
            <div className={step === 3 ? 'step step-active' : 'step'}>
              <div className='icon'>3</div>
              <div className='text'>注册完成</div>
            </div>
          </div>
          {
            step === 1 && (
              <Form form={form} layout='vertical'>
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>企业简称</div>}
                  name="shortName"
                >
                  <Input placeholder='请填写企业简称(限6个汉字)' maxLength={6} className='supplier-form-input'></Input>
                </Form.Item>
                <div className='supplier-form-tips'>
                  <span className='pointColor'>营业执照信息</span> 将应用于合同内容，请按照营业执照内容规范填写。
                </div>
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>企业全称</div>}
                  name="fullName"
                  rules={[{ required: true, message: '请填写企业全称' }]}
                >
                  <Input placeholder='请填写企业全称' className='supplier-form-input'></Input>
                </Form.Item>
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>统一社会信用代码</div>}
                  name="licenseNumber"
                  rules={[{ required: true, message: '请填写统一社会信用代码' }]}
                >
                  <Input placeholder='请填写统一社会信用代码' className='supplier-form-input'></Input>
                </Form.Item>
                {/* 企业法人 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>企业法人</div>}
                  name="legalPerson"
                  rules={[{ required: true, message: '请填写企业法人' }]}
                >
                  <Input placeholder='请填写企业法人' className='supplier-form-input'></Input>
                </Form.Item>
                {/* 企业营业执照 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>企业营业执照</div>}
                  name="license"
                  rules={[{ required: false, message: '请上传企业营业执照' }]}
                >
                  <Upload
                    name="file"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    className="supplier-upload-list-inline"
                  >
                    <PlusOutlined className="addIcon"/>
                    <div className='upload_inner_tipText'>点击上传营业执照</div>
                  </Upload>
                </Form.Item>
              </Form>
            )
          }

          {
            step === 2 && (
              <Form form={form2} layout='vertical'>
                <div className='supplier-form-tips'>
                  <span className='pointColor'>联系人信息</span> 将应用于合同内容，请规范填写。
                </div>

                {/* 联系人姓名 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>联系人姓名</div>}
                  name="contactName"
                  rules={[{ required: true, message: '请填写联系人姓名' }]}
                >
                  <Input placeholder='请填写联系人姓名(限6个汉字)' className='supplier-form-input' maxLength={6}></Input>
                </Form.Item>
                {/* 联系人身份证号码 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>联系人身份证号码</div>}
                  name="contactIdCard"
                  rules={[{ required: true, message: '请填写联系人身份证号码' }]}
                >
                  <Input placeholder='请填写联系人身份证号码' className='supplier-form-input'></Input>
                </Form.Item>
                {/* 联系人手机号 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>手机/座机号码</div>}
                  name="contactPhone"
                  rules={[{ required: true, message: '请填写手机/座机号码' }]}
                >
                  <Input placeholder='请填写手机/座机号码' className='supplier-form-input'></Input>
                </Form.Item>
                {/* 联系人邮箱 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>联系人邮箱</div>}
                  name="contactEmail"
                  rules={[{ required: true, message: '请填写联系人邮箱' }]}
                >
                  <Input placeholder='请填写联系人邮箱' className='supplier-form-input'></Input>
                </Form.Item>
                {/* 联系人微信号 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>联系人微信号</div>}
                  name="contactWechat"
                  rules={[{ required: false, message: '请填写联系人微信号' }]}
                >
                  <Input placeholder='请填写联系人微信号' className='supplier-form-input'></Input>
                </Form.Item>
                {/* 通讯地址 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>通讯地址</div>}
                  name="address"
                  rules={[{ required: true, message: '请填写通讯地址' }]}
                >
                  <Input placeholder='请填写通讯地址' className='supplier-form-input'></Input>
                </Form.Item>
                <div className='supplier-form-tips'>
                  <span className='pointColor'>根据《中华人民共和国网络安全法》、《中华人民共和国反恐怖主义法》等法律法规，要求所有使用互联网接入和互联网数据中心业务的客户都要进行实名认证。</span>
                </div>
                {/* 上传手持身份证照片 */}
                <Form.Item
                  label={<div className='supplier-form-label pointColor'>上传手持身份证照片</div>}
                  name="idCard"
                  rules={[{ required: false, message: '请上传手持身份证照片' }]}
                >
                  <Upload
                    name="file"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    className="supplier-upload-list-inline"
                  >
                    <PlusOutlined className="addIcon"/>
                    <div className='upload_inner_tipText'>点击上传手持身份证照片</div>
                  </Upload>
                </Form.Item>
              </Form>
            )
          }

          {
            step === 3 && (
              <div className='step-finish-wrap'>
                <div className='finish-icon-wrap'>
                  <img src={stepIcon3} alt='success' className='img' />
                </div>
                <div className='success-text-tt'>注册完成，已提交后台审核</div>
                <div className='success-text-stt'>审核需要2-3个工作日，完成审核后，您的邮箱会收到初始密码，请在第一次登录系统后自行更改。</div>
              </div>
            )
          }
          <div className='step-control-wrap'>
            {
              step === 1 && (
                <>
                  <div></div>
                  <div className='trapezoid step-control-btn step-next' onClick={nextStepClick}>
                    <span><i>下一步</i></span>
                    <span><img src={stepIcon2} className='step-icon-next' /></span>
                  </div>
                </>
              )
            }

            {
              step === 2 && (
                <>
                  <div className='trapezoid step-control-btn step-prev' onClick={prevStepClick}>
                    <img src={stepIcon1} className='step-icon-prev' />
                    <span><i>上一步</i></span>
                  </div>
                  <div className='trapezoid step-control-btn step-next' onClick={confirmClick}>
                    <span><i>确认提交</i></span>
                    <span><img src={stepIcon2} className='step-icon-next' /></span>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierApply;