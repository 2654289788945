
const ru = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'Главная',
      about: 'О нас',
      services: 'Услуги',
      contact: 'Контакты',
      login: 'Войти',
      register: 'Регистрация',
      logout: 'Выйти',
      country: 'Страна',
      export: 'Внешняя торговля',
      product: 'Продукт',
      contactUs: 'Связаться с нами',
    },
    btns: {
      submit: 'Отправить',
      cancel: 'Отменить',
      save: 'Сохранить',
      edit: 'Редактировать',
      delete: 'Удалить',
      add: 'Добавить',
      view: 'Просмотр',
      update: 'Обновить',
      change: 'Изменить',
      search: 'Поиск',
      loginToService: 'Войти в бэкэнд',
      partnerApply: 'Партнерская заявка',
      sendMsg: 'Отправить сообщение',
    },
    login: {
      title: 'Войти',
      username: 'Имя пользователя',
      password: 'Пароль',
      forgotPassword: 'Забыли пароль',
    },
    register: {
      title: 'Регистрация',
      username: 'Имя пользователя',
      email: 'Электронная почта',
      password: 'Пароль',
      confirmPassword: 'Подтвердите пароль',
    },
    label: {
      category: 'Категория',
      filter: 'Фильтр',
    },
    placeholderText: {
      searchInput: 'Введите ключевое слово',
      name: 'Имя',
      email: 'Электронная почта',
      phone: 'Телефон',
      message: 'Сообщение',
      company: 'Название компании',
    },
    rulesText: {
      name: 'Введите имя',
      email: 'Введите правильный адрес электронной почты',
      phone: 'Введите правильный номер телефона',
      message: 'Введите сообщение',
      company: 'Введите название компании',
    },
    title: {
      products: 'Наши продукты',
      diversity: 'Разнообразие продуктов',
      searchResults: 'Результаты поиска',
    },
    footer: {
      contact: 'Контактная информация',
      description: 'Некоторые продукты отправляются непосредственно с зарубежных облачных складов и могут прибыть в течение трех дней. От китайских заводов до ресторанов не более 20 дней.',
      phone: 'Телефон',
      email: 'Электронная почта',
      getInTouch: 'Свяжитесь с нами',
    }
  }
};


export default ru;