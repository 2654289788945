
const id = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'Beranda',
      about: 'Tentang',
      services: 'Layanan',
      contact: 'Kontak',
      login: 'Masuk',
      register: 'Daftar',
      logout: 'Keluar',
      country: 'Negara',
      export: 'Perdagangan Luar Negeri',
      product: 'Produk',
      contactUs: 'Hubungi Kami',
    },
    btns: {
      submit: 'Kirim',
      cancel: 'Batal',
      save: 'Simpan',
      edit: 'Edit',
      delete: 'Hapus',
      add: 'Tambah',
      view: 'Lihat',
      update: 'Perbarui',
      change: 'Ubah',
      search: 'Cari',
      loginToService: 'Masuk ke Layanan Backend',
      partnerApply: 'Mitra Apply',
      sendMsg: 'Kirim Pesan',
    },
    login: {
      title: 'Masuk',
      username: 'Nama Pengguna',
      password: 'Kata Sandi',
      forgotPassword: 'Lupa Kata Sandi',
    },
    register: {
      title: 'Daftar',
      username: 'Nama Pengguna',
      email: 'Surel',
      password: 'Kata Sandi',
      confirmPassword: 'Konfirmasi Kata Sandi',
    },
    label: {
      category: 'Kategori',
      filter: 'Filter',
    },
    placeholderText: {
      searchInput: 'Masukkan kata kunci',
      name: 'Nama',
      email: 'Surel',
      phone: 'Telepon',
      message: 'Pesan',
      company: 'Nama Perusahaan',
    },
    rulesText: {
      name: 'Masukkan Nama',
      email: 'Masukkan Surel',
      phone: 'Masukkan Nomor Telepon',
      message: 'Masukkan Pesan',
      company: 'Masukkan Nama Perusahaan',
    },
    title: {
      products: 'Produk Kami',
      diversity: 'Keragaman Produk',
      searchResults: 'Hasil Pencarian',
    },
    footer: {
      contact: 'Kontak',
      description: 'Beberapa produk dikirim langsung dari gudang awan luar negeri dan dapat tiba dalam tiga hari tidak akan butuh lebih dari 20 hari dari pabrik Cina ke restoran.',
      phone: 'Telepon',
      email: 'Email',
      getInTouch: 'Hubungi Kami',
    }
  }
};


export default id;