
const th = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'หน้าแรก',
      about: 'เกี่ยวกับ',
      services: 'บริการ',
      contact: 'ติดต่อ',
      login: 'เข้าสู่ระบบ',
      register: 'ลงทะเบียน',
      logout: 'ออกจากระบบ',
      country: 'ประเทศ',
      export: 'การค้าต่างประเทศ',
      product: 'สินค้า',
      contactUs: 'ติดต่อเรา',
    },
    btns: {
      submit: 'ส่ง',
      cancel: 'ยกเลิก',
      save: 'บันทึก',
      edit: 'แก้ไข',
      delete: 'ลบ',
      add: 'เพิ่ม',
      view: 'ดู',
      update: 'อัพเดท',
      change: 'เปลี่ยน',
      search: 'ค้นหา',
      loginToService: 'เข้าสู่บริการ',
      partnerApply: 'สมัครเป็นพาร์ทเนอร์',
      sendMsg: 'ส่งข้อความ',
    },
    login: {
      title: 'เข้าสู่ระบบ',
      username: 'ชื่อผู้ใช้',
      password: 'รหัสผ่าน',
      forgotPassword: 'ลืมรหัสผ่าน',
    },
    register: {
      title: 'ลงทะเบียน',
      username: 'ชื่อผู้ใช้',
      email: 'อีเมล',
      password: 'รหัสผ่าน',
      confirmPassword: 'ยืนยันรหัสผ่าน',
    },
    label: {
      category: 'ประเภท',
      filter: 'กรอง',
    },
    placeholderText: {
      searchInput: 'ป้อนคำหลัก',
      name: 'ชื่อ',
      phone: 'โทรศัพท์',
      email: 'อีเมล',
      message: 'ข้อความ',
      company: 'ชื่อบริษัท',
    },
    rulesText: {
      name: 'โปรดป้อนชื่อ',
      email: 'โปรดป้อนอีเมล',
      phone: 'โปรดป้อนหมายเลขโทรศัพท์',
      message: 'โปรดป้อนข้อความ',
      company: 'โปรดป้อนชื่อบริษัท',
    },
    title: {
      products: 'สินค้าของเรา',
      diversity: 'ความหลากหลายของผลิตภัณฑ์',
      searchResults: 'ผลการค้นหา',
    },
    footer: {
      contact: 'รายละเอียดการติดต่อ',
      description: 'ผลิตภัณฑ์บางอย่างจัดส่งโดยตรงจากคลังสินค้า Cloud ในต่างประเทศและสามารถเข้าถึงได้ภายในสามวัน ไม่เกิน 20 วันจากโรงงานจีนไปยังร้านอาหาร',
      phone: 'โทรศัพท์',
      email: 'อีเมล',
      getInTouch: 'ติดต่อเรา',
    }
  }
};


export default th;