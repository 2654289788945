import { Row, Col, Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import './contact-us.scss';

const icon1 = require('@/assets/contact-icons/1.png');
const icon2 = require('@/assets/contact-icons/2.png');
const icon3 = require('@/assets/contact-icons/3.png');

function ContactUs() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const confirmClick = () => {
    form.validateFields().then(values => {
      console.log(values);
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <>
      <div className='contact-top-banner'>
        <div className='content-width'>
          <div className='contact-tt'>关于我们</div>
          <div className='contact-tt-desc'>让中餐供应链企业跨境销售更容易</div>
        </div>
      </div>
      <div className='contact-main-container'>
        <div className='content-width'>
          <Row gutter={60}>
            <Col span={12} className='contact-desc'>
              <div className='contact-desc-inner-box'>
                <div className='tt'>Eight Dish 中餐供应链</div><div className='tt'>跨境综合服务平台</div>
                <div className='stt'>专业的一站式中餐供应链跨境服务商</div>
                <div className='us-intro'>
                  <span>Eight Dish</span> 是一个专业的中餐供应链(冷链预制菜)跨境综合服务平台，专注于为中餐供应链(冷链预制菜)提供贯穿于中餐供应链企业跨境出海全生命周期中所需要的所有服务。
                </div>
                <div className='us-intro'>
                  <span>Eight Dish</span> 平台代表中餐供应链出海的新时代，它是一个集电商服务、支付服务和物流企业于一体的综合平台，旨在为中餐供应链的所有企业出海提供一站式服务，构建一个无缝连接的数字化生态系统。帮助中餐供应链企业出海降本、增效、提速，让世界认识中餐供应链。
                </div>
              </div>
            </Col>
            <Col span={12} style={{ paddingRight: 110 }}>
              <Row gutter={20}>
                <Col span={8}>
                  <div className='contact-item'>
                    <div className='contact-item-icon contact-item-icon-1'></div>
                    <div className='contact-item-tt'>合规准入</div>
                    <div className='contact-item-desc'>国内出口许可</div>
                    <div className='contact-item-desc'>国内海关备案</div>
                    <div className='contact-item-desc'>目标国准入许可</div>
                    <div className='contact-item-desc'>进口报关</div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className='contact-item'>
                    <div className='contact-item-icon contact-item-icon-2'></div>
                    <div className='contact-item-tt'>冷量运输</div>
                    <div className='contact-item-desc'>端到端运输</div>
                    <div className='contact-item-desc'>冷链物流</div>
                    <div className='contact-item-desc'>海外冷链仓储</div>
                    <div className='contact-item-desc'>海外代发</div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className='contact-item'>
                    <div className='contact-item-icon contact-item-icon-3'></div>
                    <div className='contact-item-tt'>跨境商城</div>
                    <div className='contact-item-desc'>海外销售独立站</div>
                    <div className='contact-item-desc'>海外营销助力</div>
                    <div className='contact-item-desc'>多货币销售</div>
                    <div className='contact-item-desc'>平台直销</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className='content-width contact-form-wrap'>
        <Row gutter={40}>
          <Col span={12}>
            <div className='footer-t'>{t('footer.contact')}</div>
            <div className='contact-item'>
              <div className='contact-t'>{t('footer.phone')}</div>
              <div className='contact-desc'>+86 123456789</div>
            </div>
            <div className='contact-item'>
              <div className='contact-t'>{t('footer.email')}</div>
              <div className='contact-desc'>contact@eightdish.com</div>
            </div>
          </Col>
          <Col span={12}>
            <div className='footer-t'>{t('footer.getInTouch')}</div>
            <Form
              autocomplete='off'
              form={form}
            >
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                rules={[{ required: true, message: t('rulesText.name') }]}
                name='name'
              >
                <Input placeholder={t('placeholderText.name')} autocomplete='off' className='input-area' />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                rules={[{ required: true, message: t('rulesText.email') }]}
                name='email'
              >
                <Input placeholder={t('placeholderText.email')} autocomplete='off' className='input-area' />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                rules={[{ required: true, message: t('rulesText.phone') }]}
                name='phone'
              >
                <Input placeholder={t('placeholderText.phone')} autocomplete='off' className='input-area' />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                rules={[{ required: true, message: t('rulesText.company') }]}
                name='company'
              >
                <Input placeholder={t('placeholderText.company')} autocomplete='off' className='input-area' />
              </Form.Item>
              <Form.Item
                name='message'
                rules={[{ required: true, message: t('rulesText.message') }]}
              >
                <Input.TextArea rows={6} placeholder={t('placeholderText.message')} className='input-area' />
              </Form.Item>
              <Form.Item
                style={{ textAlign: 'right' }}
              >
                <Button type='primary' className='form-btn' onClick={confirmClick}>{t('btns.sendMsg')}</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactUs;