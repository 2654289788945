import en from './en';
import cn from './cn';
import ru from './ru';
import id from './id';
import th from './th';
import vie from './vie';
import ma from './ma';
import ph from './ph';

export const languages = ['cn', 'en', 'id', 'ma', 'ph', 'ru', 'th', 'vie'];

export default {
  en,
  cn,
  ru,
  id,
  th,
  vie,
  ma,
  ph
};
