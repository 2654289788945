import { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode, Thumbs } from 'swiper/modules';
import { handleSwiperData } from '@/utils/index';
import { useTranslation } from 'react-i18next';

import './products.scss';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Autoplay, FreeMode, Thumbs]);

function CommonProducts(props) {
  const { region } = props;
  const [swiperData, setSwiperData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    console.log('region has changed:', region);
    let totalData = handleSwiperData([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21], 10);
    setSwiperData(totalData);
  }, [region]);

  return (
    <>
      <div className='swiper-list2-container'>
        <div className='content-width'>
          
          <div className='list2-title-wrap'>
            <div className='tt'>{t('title.products')}</div>
            <div className='stt'>{t('title.diversity')}</div>
          </div>

          <div className='s-c'>
            <Swiper
              modules={[FreeMode, Navigation, Thumbs]}
              navigation={{
                nextEl: '.button-next2',
                prevEl: '.button-prev2',
              }}
              loop
              autoplay={false}
              className='swiper-container'
            >
              {
                swiperData.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className='slide-container'>
                        {
                          item.map((proItem, proIndex) => {
                            return (
                              <div className='pro-item2' key={proIndex}>
                                <div className='img-wrap'>
                                  <img src='https://img1.baidu.com/it/u=2917133926,3765530658&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500' alt='search' />
                                </div>
                                <div className='pro-name' title='Steamed Fish Head with Diced Hot Red Peppers'>Steamed Fish Head with Diced Hot Red Peppers</div>
                                <div className='pro-sub-name' title='Savor the spicy delight of Chopped Chili Fish Head, a Sichuan specialty featuring tender fish head braised in a fiery blend of freshly chopped chilies, garlic, and soy sauce, igniting your taste buds with a fiery yet harmonious balance.'>Savor the spicy delight of Chopped Chili Fish Head, a Sichuan specialty featuring tender fish head braised in a fiery blend of freshly chopped chilies, garlic, and soy sauce, igniting your taste buds with a fiery yet harmonious balance.</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>

            <div className='switchButton button-prev button-prev2'>
              <LeftOutlined className='icon' />
            </div>
            <div className='switchButton button-next button-next2'>
              <RightOutlined className='icon' />
            </div>
          </div>

        </div>
      </div>
    </>
  )
};

export default CommonProducts;