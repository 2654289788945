import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  let { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({  
      top: 0,  
      left: 0,  
      behavior: 'smooth'
    });  
  }, [pathname]);

  return null; // 组件不渲染任何内容  
}

export default ScrollToTop;