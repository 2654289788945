import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode, Thumbs } from 'swiper/modules';

import './home.scss';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Autoplay, FreeMode, Thumbs]);

const itemImg1 = require('@/assets/service-item1.png');
const itemImg2 = require('@/assets/service-item2.png');
const itemImg3 = require('@/assets/service-item3.png');
const itemImg4 = require('@/assets/service-item4.png');

const arrowImg = require('@/assets/arrow_1.png');

function HomePage() {
  const navigate = useNavigate();
  const [currentRegion, setRegion] = useState({
    regionName: '新加坡',
    regionEnName: 'Singapore'
  });
  
  return (
    <>
      <div className='home-main-container'>
        <div className='top-banner-content'>
          <div className='home-banner-tt'>标题文字</div>
          <div className='home-banner-subTt'>副标题文字</div>
          <div className='trapezoid top-btn'><span>Button</span></div>
        </div>
      </div>
      <div className='region-banner'>
        <div className='region-content'>
          <div className='tt'>出海东南亚 泰国</div>
          <div className='region-content-btns'>
            <div className='trapezoid btn-supplier'><span>供应商入驻</span></div>
            <div className='trapezoid btn-business'><span>查看入驻商家</span></div>
          </div>
        </div>
      </div>
      <div className='intro-content'>
        <div className='content-width intro-wrap'>
          <div className='intro-title'>中餐预制菜代理出口服务</div>
          <div className='intro-stt'>出口代理4+N服务 助力发展新市场</div>
          <div className='intro-service-wrap'>
            <div className='service-item'>
              <div className='item-img-wrap'>
                <img alt='' src={itemImg1} className='img' />
              </div>
              <div className='service-item-title'>
                <div>全程</div>
                <div>冷链物流</div>
              </div>
              <div className='service-item-subTitle'>
                从工厂到码头，再到目的国仓库，全程零下18°冷链运输
              </div>
            </div>
            <div className='service-item'>
              <div className='item-img-wrap'>
                <img alt='' src={itemImg2} className='img' />
              </div>
              <div className='service-item-title'>
                <div>目标国</div>
                <div>合规认证</div>
              </div>
              <div className='service-item-subTitle'>
                从工厂到码头，再到目的国仓库，全程零下18°冷链运输
              </div>
            </div>
            <div className='service-item'>
              <div className='item-img-wrap'>
                <img alt='' src={itemImg3} className='img' />
              </div>
              <div className='service-item-title'>
                <div>目标国</div>
                <div>清关服务</div>
              </div>
              <div className='service-item-subTitle'>
                从工厂到码头，再到目的国仓库，全程零下18°冷链运输
              </div>
            </div>
            <div className='service-item'>
              <div className='item-img-wrap'>
                <img alt='' src={itemImg4} className='img' />
              </div>
              <div className='service-item-title'>
                <div>海外仓</div>
                <div>（冻库）</div>
              </div>
              <div className='service-item-subTitle'>
                从工厂到码头，再到目的国仓库，全程零下18°冷链运输
              </div>
            </div>
          </div>
          <div className='trapezoid intro-bg'>
            <span>准入</span>
            <img alt='' src={arrowImg} className='img' />
            <span>下单通关</span>
            <img alt='' src={arrowImg} className='img' />
            <span>冷链物流</span>
            <img alt='' src={arrowImg} className='img' />
            <span>海外（冷链）仓</span>
            <img alt='' src={arrowImg} className='img' />
            <span>本地代发</span>
          </div>
        </div>
      </div>
      <div className='intro-content-t'>
        <div className='content-width'>
          <Swiper
            modules={[FreeMode, Navigation, Thumbs]}
            loop
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            speed={1000}
            className='swiper-container'
          >
            <SwiperSlide className='swiper-slide'>
              banner 1
              <div>5分钟急速建站</div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide'>
              banner 2
              <div>尽收全球海量订单</div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  )
};

export default HomePage;