import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutComponent from '@/components/layout';

import HomePage from '@/pages/home';
import ExportService from '@/pages/export-service';
import ContactUs from '@/pages/contact-us';
import CommonProducts from '@/pages/products';
import SupplierApply from '@/pages/supplier-apply';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LayoutComponent />}>
          <Route index element={<HomePage />}></Route>
          <Route path='export-service' element={<ExportService />}></Route>
          <Route path='contact-us' element={<ContactUs />}></Route>
          <Route path='products' element={<CommonProducts />}></Route>
          <Route path='/supplier-apply' element={<SupplierApply />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
