
const ma = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'Laman Utama',
      about: 'Mengenai',
      services: 'Perkhidmatan',
      contact: 'Hubungi',
      login: 'Log Masuk',
      register: 'Daftar',
      logout: 'Log Keluar',
      country: 'Negara',
      export: 'Perdagangan Luar Negeri',
      product: 'Produk',
      contactUs: 'Hubungi Kami',
    },
    btns: {
      submit: 'Hantar',
      cancel: 'Batal',
      save: 'Simpan',
      edit: 'Edit',
      delete: 'Padam',
      add: 'Tambah',
      view: 'Lihat',
      update: 'Kemaskini',
      change: 'Ubah',
      search: 'Cari',
      loginToService: 'Log Masuk ke Backend Perkhidmatan',
      partnerApply: 'Minta Rakan Kongsi',
      sendMsg: 'Hantar Mesej',
    },
    login: {
      title: 'Log Masuk',
      username: 'Nama Pengguna',
      password: 'Kata Laluan',
      forgotPassword: 'Lupa Kata Laluan',
    },
    register: {
      title: 'Daftar',
      username: 'Nama Pengguna',
      email: 'Emel',
      password: 'Kata Laluan',
      confirmPassword: 'Sahkan Kata Laluan',
    },
    label: {
      category: 'Kategori',
      filter: 'Tapis',
    },
    placeholderText: {
      searchInput: 'Masukkan kata kunci',
      name: 'Nama',
      email: 'Emel',
      phone: 'Telefon',
      message: 'Mesej',
      company: 'Nama Syarikat',
    },
    rulesText: {
      name: 'Sila masukkan nama',
      email: 'Sila masukkan emel',
      phone: 'Sila masukkan nombor telefon',
      message: 'Sila masukkan mesej',
      company: 'Sila masukkan nama syarikat',
    },
    title: {
      products: 'Produk Kami',
      diversity: 'Keragaman Produk',
      searchResults: 'Hasil Carian',
    },
    footer: {
      contact: 'Maklumat kenalan',
      description: 'Beberapa produk dihantar langsung dari gudang awan luar negeri dan boleh tiba dalam tiga hari ia tidak akan mengambil lebih dari 20 hari dari kilang Cina ke restoran.',
      phone: 'Telepon',
      email: 'Email',
      getInTouch: 'Hubungi Kami',
    }
  }
};

export default ma;