
const en = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'Home',
      about: 'About',
      services: 'Services',
      contact: 'Contact',
      login: 'Login',
      register: 'Register',
      logout: 'Logout',
      country: 'Country',
      export: 'Foreign Trade',
      product: 'Product',
      contactUs: 'Contact Us',
    },
    btns: {
      submit: 'Submit',
      cancel: 'Cancel',
      save: 'Save',
      edit: 'Edit',
      delete: 'Delete',
      add: 'Add',
      view: 'View',
      update: 'Update',
      change: 'Change',
      search: 'Search',
      loginToService: 'Login to Service Backend',
      partnerApply: 'Partner Apply',
      sendMsg: 'Send Message',
    },
    login: {
      title: 'Login',
      username: 'Username',
      password: 'Password',
      forgotPassword: 'Forgot Password',
    },
    register: {
      title: 'Register',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
    },
    label: {
      category: 'Category',
      filter: 'Filter',
    },
    placeholderText: {
      searchInput: 'Enter keyword',
      name: 'Name',
      email: 'Email',
      message: 'Leave a Message',
      phone: 'Phone Number',
      company: 'Company Name',
    },
    rulesText: {
      name: 'Please input your name',
      email: 'Please input a valid email address',
      message: 'Please input a message',
      phone: 'Please input a phone number',
      company: 'Please input your company name',
    },
    title: {
      products: 'Our Products',
      diversity: 'Product Diversity',
      searchResults: 'Search Results',
    },
    footer: {
      contact: 'Contact',
      description: 'Some products are shipped directlyfrom overseas cloud warehouses andcan arrive within three daysIt will take no more than 20 days fromthe Chinese factory to the restaurant.',
      phone: 'Phone Number',
      email: 'Email',
      getInTouch: 'Get in Touch',
    }
  }
};


export default en;