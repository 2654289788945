
const cn = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: '首页',
      about: '关于',
      services: '服务',
      contact: '联系',
      login: '登录',
      register: '注册',
      logout: '登出',
      country: '国家/地区',
      export: '外贸服务',
      product: '产品中心',
      contactUs: '联系我们',
    },
    btns: {
      submit: '提交',
      cancel: '取消',
      save: '保存',
      edit: '编辑',
      delete: '删除',
      add: '添加',
      view: '查看',
      update: '更新',
      change: '修改',
      search: '搜索',
      loginToService: '登录服务后台',
      partnerApply: '合作商申请',
      sendMsg: '发送消息',
    },
    login: {
      title: '登录',
      username: '用户名',
      password: '密码',
      forgotPassword: '忘记密码',
    },
    register: {
      title: '注册',
      username: '用户名',
      email: '电子邮件',
      password: '密码',
      confirmPassword: '确认密码',
    },
    label: {
      category: '所属类目',
      filter: '筛选',
    },
    placeholderText: {
      searchInput: '请输入关键字',
      name: '姓名',
      email: '邮件地址',
      message: '留言信息',
      phone: '联系电话',
      company: '公司名称',
    },
    rulesText: {
      name: '请输入姓名',
      email: '请输入正确的邮件地址',
      message: '请输入留言信息',
      phone: '请输入联系电话',
      company: '请输入公司名称',
    },
    title: {
      products: '我们的产品',
      diversity: '产品多样性',
      searchResults: '搜索结果',
    },
    footer: {
      contact: '联系方式',
      about: '关于我们',
      privacy: '隐私政策',
      terms: '服务条款',
      faq: '常见问题',
      help: '帮助',
      social: '社交媒体',
      rights: '版权所有',
      description: '部分产品直接从海外云仓库发货，可以在三天内到达。从中国工厂到餐厅不超过20天。',
      phone: '联系电话',
      email: '电子邮件',
      getInTouch: '联系我们',
    }
  }
};

export default cn;