import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Navbar() {

  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  
  return (
    <>
      <div className="header-nav-bar">
        <Link to={'/'} className={pathname === '/' ? "nav-link is-active" : 'nav-link'}>{t('menu.home')}</Link>
        <Link to={'/export-service'} className={pathname === '/export-service' ? "nav-link is-active" : 'nav-link'}>{t('menu.export')}</Link>
        <Link to={'https://www.eightdish.com'} className={pathname === '/products' ? "nav-link is-active" : 'nav-link'}>{t('menu.product')}</Link>
        <Link to={'/contact-us'} className={pathname === '/contact-us' ? "nav-link is-active" : 'nav-link'}>{t('menu.contactUs')}</Link>
      </div>
    </>
  )
};

export default Navbar;