import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import './export-service.scss';

const icon1 = require('@/assets/service-icon/icon1.png');
const icon2 = require('@/assets/service-icon/icon2.png');
const icon3 = require('@/assets/service-icon/icon3.png');
const icon4 = require('@/assets/service-icon/icon4.png');
const icon1a = require('@/assets/service-icon/icon1-a.png');
const icon2a = require('@/assets/service-icon/icon2-a.png');
const icon3a = require('@/assets/service-icon/icon3-a.png');
const icon4a = require('@/assets/service-icon/icon4-a.png');

const shipImg = require('@/assets/ship.png');

const serviceIcon1 = require('@/assets/icons/service-icon1.png');
const serviceIcon2 = require('@/assets/icons/service-icon2.png');
const serviceIcon3 = require('@/assets/icons/service-icon3.png');
const serviceIcon4 = require('@/assets/icons/service-icon4.png');
const serviceIcon5 = require('@/assets/icons/service-icon5.png');
const serviceIcon6 = require('@/assets/icons/service-icon6.png');

function ExportService() {
  const [iconIndex, setIconIndex] = useState(0);

  const navigate = useNavigate();
 
  return (
    <>
      <div className='export-top-banner'>
        <div className='export-top-content'>
          <div className='banner-title'>代理出口服务</div>
          <div className='banner-sub-title'>4+N专业服务，全链路赋能</div>
          <div className='banner-sub-title'>中餐冷链供应链一站式服务商</div>
          <div className='banner-sub-title'>解决中餐供应链企业出海的</div>
          <div className='banner-sub-title'>“出口资质”冷链供应与“销售渠道”问题</div>
          <div className='trapezoid top-btn' onClick={() => navigate('/contact-us')}><span>联系我们</span></div>
        </div>
      </div>
      <div className='export-intro-wrap'>
        <div className='content-width export-service-list'>
          <div className='export-service-item' onMouseOver={()=>setIconIndex(1)} onMouseLeave={()=>setIconIndex(0)}>
            <div className='item-img-wrap'>
              <img alt='' src={iconIndex === 1 ? icon1a : icon1} className='img' />
            </div>
            <div className='service-item-title'>
              <div>全程零下18°</div>
              <div>冷链物流</div>
            </div>
            <div className='service-item-subTitle'>
              产品运输服务
            </div>
          </div>
          <div className='export-service-item' onMouseOver={()=>setIconIndex(2)} onMouseLeave={()=>setIconIndex(0)}>
            <div className='item-img-wrap'>
              <img alt='' src={iconIndex === 2 ? icon2a : icon2} className='img' />
            </div>
            <div className='service-item-title'>
              <div>目标国</div>
              <div>合规认证</div>
            </div>
            <div className='service-item-subTitle'>
              产品准入认证
            </div>
          </div>
          <div className='export-service-item' onMouseOver={()=>setIconIndex(3)} onMouseLeave={()=>setIconIndex(0)}>
            <div className='item-img-wrap'>
              <img alt='' src={iconIndex === 3 ? icon3a : icon3} className='img' />
            </div>
            <div className='service-item-title'>
              <div>进出口海关</div>
              <div>报关清关服务</div>
            </div>
            <div className='service-item-subTitle'>
              海关双清服务
            </div>
          </div>
          <div className='export-service-item' onMouseOver={()=>setIconIndex(4)} onMouseLeave={()=>setIconIndex(0)}>
            <div className='item-img-wrap'>
              <img alt='' src={iconIndex === 4 ? icon4a : icon4} className='img' />
            </div>
            <div className='service-item-title'>
              <div>海外仓冻库</div>
              <div>及代发</div>
            </div>
            <div className='service-item-subTitle'>
              产品仓储服务
            </div>
          </div>
        </div>
      </div>
      <div className='export-intro-wrap2'>
        <div className='content-width export-desc-wrap'>
          <div className='export-desc'><span className='tt'>Eight Dish</span> 代理出口服务一站式服务平台的核心理念是"多方共赢"。汇聚了冷链预制菜供应链上的各类企业，包括合规准入、报关代理、冷链仓储和冷链物流公司等，形成了一个紧密合作系统。这种紧密合作不仅为预制菜出海企业提供了全面的服务，还提高了整个供应链的效率。</div>
          <div className='desc-subContent-wrap'>
            <div className='sub-content-item'>
              <div className='item-img-wrap'>
                <img alt='' src={shipImg} className='img' />
              </div>
              <div className='item-content-wrap'>
                <div>专业货轮</div>
                <div className='n'>18800+</div>
              </div>
            </div>
            <div className='divider-line'></div>
            <div className='sub-content-item'>
              <div className='item-img-wrap'>
                <img alt='' src={shipImg} className='img' />
              </div>
              <div className='item-content-wrap'>
                <div>专业货轮</div>
                <div className='n'>18800+</div>
              </div>
            </div>
            <div className='divider-line'></div>
            <div className='sub-content-item'>
              <div className='item-img-wrap'>
                <img alt='' src={shipImg} className='img' />
              </div>
              <div className='item-content-wrap'>
                <div>专业货轮</div>
                <div className='n'>18800+</div>
              </div>
            </div>
            <div className='divider-line'></div>
            <div className='sub-content-item'>
              <div className='item-img-wrap'>
                <img alt='' src={shipImg} className='img' />
              </div>
              <div className='item-content-wrap'>
                <div>专业货轮</div>
                <div className='n'>18800+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='export-intro-wrap3'>
        <div className='content-width'>
          <div className='wrap3-title'>细分市场 精准营销</div>
          <div className='wrap3-mt'>Eight Dish 渠道分销服务</div>
          <Row gutter={[20,20]} className='wrap3-intro-container'>
            <Col span={8}>
              <Card className='wrap3-intro-item'>
                <div className='wrap3-intro-item-inner'>
                  <div className='inner-left'>
                    <div className='wrap3-intro-item-tt'>Ul/UX Graphic Design</div>
                    <div className='wrap3-item-desc'>Assertively fabricate impactful There infomedia vis-a-vis error-free process improve Distinctively</div>
                  </div>
                  <div className='inner-right'>
                    <img alt='' src={serviceIcon1}  className='icon'/>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className='wrap3-intro-item'>
                <div className='wrap3-intro-item-inner'>
                  <div className='inner-left'>
                    <div className='wrap3-intro-item-tt'>Ul/UX Graphic Design</div>
                    <div className='wrap3-item-desc'>Assertively fabricate impactful There infomedia vis-a-vis error-free process improve Distinctively</div>
                  </div>
                  <div className='inner-right'>
                    <img alt='' src={serviceIcon2}  className='icon'/>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className='wrap3-intro-item'>
                <div className='wrap3-intro-item-inner'>
                  <div className='inner-left'>
                    <div className='wrap3-intro-item-tt'>Ul/UX Graphic Design</div>
                    <div className='wrap3-item-desc'>Assertively fabricate impactful There infomedia vis-a-vis error-free process improve Distinctively</div>
                  </div>
                  <div className='inner-right'>
                    <img alt='' src={serviceIcon3}  className='icon'/>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className='wrap3-intro-item'>
                <div className='wrap3-intro-item-inner'>
                  <div className='inner-left'>
                    <div className='wrap3-intro-item-tt'>Ul/UX Graphic Design</div>
                    <div className='wrap3-item-desc'>Assertively fabricate impactful There infomedia vis-a-vis error-free process improve Distinctively</div>
                  </div>
                  <div className='inner-right'>
                    <img alt='' src={serviceIcon4}  className='icon'/>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className='wrap3-intro-item'>
                <div className='wrap3-intro-item-inner'>
                  <div className='inner-left'>
                    <div className='wrap3-intro-item-tt'>Ul/UX Graphic Design</div>
                    <div className='wrap3-item-desc'>Assertively fabricate impactful There infomedia vis-a-vis error-free process improve Distinctively</div>
                  </div>
                  <div className='inner-right'>
                    <img alt='' src={serviceIcon5}  className='icon'/>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className='wrap3-intro-item'>
                <div className='wrap3-intro-item-inner'>
                  <div className='inner-left'>
                    <div className='wrap3-intro-item-tt'>Ul/UX Graphic Design</div>
                    <div className='wrap3-item-desc'>Assertively fabricate impactful There infomedia vis-a-vis error-free process improve Distinctively</div>
                  </div>
                  <div className='inner-right'>
                    <img alt='' src={serviceIcon6}  className='icon'/>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <div className='export-desc'><span className='tt'>Eight Dish</span> 渠道分销服务帮助预制菜企业建立海外销售渠道，开展销售业务。不但能建立海外直销，更能建立分销体系。从数据分析，选品开始，到全段供应链物流支持，再到分销渠道建设，营销培训，最后实在资金结算，在其中各个环节提供服务，为预制菜企业高效拓展销售业务。</div>
          <div className='wrap3-btn-wrap'>
            <div className='trapezoid btn-export' onClick={() => navigate('/contact-us')}><span>联系我们</span></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportService;