
const vie = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'Trang chủ',
      about: 'Về chúng tôi',
      services: 'Dịch vụ',
      contact: 'Liên hệ',
      login: 'Đăng nhập',
      register: 'Đăng ký',
      logout: 'Đăng xuất',
      country: 'Quốc gia',
      export: 'Ngoại thương',
      product: 'Sản phẩm',
      contactUs: 'Liên hệ với chúng tôi',
    },
    btns: {
      submit: 'Gửi',
      cancel: 'Hủy',
      save: 'Lưu',
      edit: 'Chỉnh sửa',
      delete: 'Xóa',
      add: 'Thêm',
      view: 'Xem',
      update: 'Cập nhật',
      change: 'Thay đổi',
      search: 'Tìm kiếm',
      loginToService: 'Đăng nhập vào dịch vụ',
      partnerApply: 'Đối tác ứng tuyển',
      sendMsg: 'Gửi tin nhắn',
    },
    login: {
      title: 'Đăng nhập',
      username: 'Tên người dùng',
      password: 'Mật khẩu',
      forgotPassword: 'Quên mật khẩu',
    },
    register: {
      title: 'Đăng ký',
      username: 'Tên người dùng',
      email: 'Email',
      password: 'Mật khẩu',
      confirmPassword: 'Xác nhận mật khẩu',
    },
    label: {
      category: 'Danh mục',
      filter: 'Bộ lọc',
    },
    placeholderText: {
      searchInput: 'Nhập từ khóa',
      name: 'Tên',
      email: 'Email',
      phone: 'Điện thoại',
      message: 'Tin nhắn',
      company: 'Tên công ty',
    },
    rulesText: {
      name: 'Vui lòng nhập tên',
      email: 'Vui lòng nhập email',
      phone: 'Vui lòng nhập số điện thoại',
      message: 'Vui lòng nhập tin nhắn',
      company: 'Vui lòng nhập tên công ty',
    },
    title: {
      products: 'Sản phẩm của chúng tôi',
      diversity: 'Đa dạng sản phẩm',
      searchResults: 'Kết quả tìm kiếm',
    },
    footer: {
      contact: 'Thông tin liên hệ',
      description: 'Một số sản phẩm được vận chuyển trực tiếp từ kho đám mây ở nước ngoài và có thể đến trong vòng ba ngày. Không quá 20 ngày từ nhà máy Trung Quốc đến nhà hàng.',
      phone: 'Điện thoại',
      email: 'Email',
      getInTouch: 'Liên hệ',
    }
  }
};

export default vie;