import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Navbar from './navbar';

import { useTranslation } from 'react-i18next';
import { languages } from '@/i18n/lang/index';

import ScrollToTop from '@/components/scroll-to-top';

const { Header, Footer, Content } = Layout;

const logoImg = require('@/assets/logo.png');

function LayoutComponent() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [currentRegion, setCurrentRegion] = useState('cn');

  const [toggleRegionSelectWrapVisible, setToggleRegionSelectWrapVisible] = useState(false);

  const setRegion = (region) => {
    setCurrentRegion(region);
    i18n.changeLanguage(region);
    setToggleRegionSelectWrapVisible(false);
  }

  const toggleRegionSelectWrap = () => {
    setToggleRegionSelectWrapVisible(!toggleRegionSelectWrapVisible);
  }

  const getResetRouter = () => {
    navigate('/');
  }

  const applyClick = () => {
    navigate('/supplier-apply');
  }

  return (
    <>
      <ScrollToTop />
      <Layout className='layout-container'>
        <Header className='layout-header'>
          <div className='logo-img' onClick={getResetRouter}>
            <img alt='Eigth Dish' src={logoImg} className='logo' />
          </div>
          <Navbar />
          <div className='header-right-container'>
            <span className='nation-link' onClick={toggleRegionSelectWrap}>
              <img src={require(`@/assets/flags/${currentRegion}.png`)} className='currentRgionFlag' />
              <span className='currentRgionName'>{t(`regions.${currentRegion}`)}</span>
              {
                toggleRegionSelectWrapVisible ? <CaretUpOutlined className='down-icon' /> : <CaretDownOutlined className='down-icon' />
              }
            </span>
            <div className="trapezoid btn-login"><span>{t('btns.loginToService')}</span></div>
            <div className='trapezoid btn-partner' onClick={applyClick}><span>{t('btns.partnerApply')}</span></div>
          </div>

          <div className={toggleRegionSelectWrapVisible ? 'region-area region-show' : 'region-area region-hide'}>
            <div className='content-width'>
              <Row gutter={[40, 20]}>
                {
                  languages.map((item, index) => {
                    return (
                      <Col span={6} key={index}>
                        <div className='region-item' onClick={() => setRegion(item)}>
                          <img src={require(`@/assets/flags/${item}.png`)} className='flag' />
                          <span className='region-name'>{t(`regions.${item}`)}</span>
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </div>
        </Header>
        <Content className='layout-content'>
          <Outlet />
        </Content>
      </Layout>
    </>
  )
};

export default LayoutComponent;