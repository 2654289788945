import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from "antd";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import 'antd/dist/reset.css';
import './index.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#0029FF'
      },

    }}
  >
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </ConfigProvider>
);
