
const ph = {
  translation: {
    regions: {
      "cn": "中文",
      "en": "English",
      "ru": "Русский язык",
      "id": "Indonesia",
      "th": "ภาษาไทย",
      "vie": "Tiếng Việt",
      "ma": "Malaysia",
      "ph": "Tagalog",
    },
    menu: {
      home: 'Bahay',
      about: 'Tungkol sa',
      services: 'Serbisyo',
      contact: 'Makipag-ugnay',
      login: 'Mag-login',
      register: 'Magparehistro',
      logout: 'Mag-logout',
      country: 'Bansa',
      export: 'Foreign Trade',
      product: 'Produkto',
      contactUs: 'Makipag-ugnay sa amin',
    },
    btns: {
      submit: 'Ipasa',
      cancel: 'Kanselahin',
      save: 'I-save',
      edit: 'I-edit',
      delete: 'Tanggalin',
      add: 'Magdagdag',
      view: 'Tingnan',
      update: 'I-update',
      change: 'Baguhin',
      search: 'Maghanap',
      loginToService: 'Mag-login sa Serbisyo Backend',
      partnerApply: 'Mag-aplay bilang Partner',
      sendMsg: 'Magpadala ng Mensahe',
    },
    login: {
      title: 'Mag-login',
      username: 'Username',
      password: 'Password',
      forgotPassword: 'Nakalimutan ang Password',
    },
    register: {
      title: 'Magparehistro',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Kumpirmahin ang Password',
    },
    label: {
      category: 'Kategorya',
      filter: 'Salain',
    },
    placeholderText: {
      searchInput: 'Magpasok ng keyword',
      name: 'Pangalan',
      email: 'Email',
      phone: 'Numero ng Telepono',
      message: 'Mensahe',
      company: 'Pangalan ng Kumpanya',
    },
    rulesText: {
      name: 'Mangyaring magpasok ng pangalan',
      email: 'Mangyaring magpasok ng email',
      phone: 'Mangyaring magpasok ng numero ng telepono',
      message: 'Mangyaring mag-iwan ng mensahe',
      company: 'Mangyaring magpasok ng pangalan ng kumpanya',
    },
    title: {
      products: 'Ang Aming Mga Produkto',
      diversity: 'Diversity ng Produkto',
      searchResults: 'Mga Resulta ng Paghahanap',
    },
    footer: {
      contact: 'Contact Information',
      description: 'Ang ilan sa mga produkto ay ipinadala sa direktang aklatan mula sa mga tindahan ng ulap sa ibang bansa at maaaring dumating sa loob ng tatlong araw Hindi ito magtatagal ng higit sa 20 araw mula sa pabrika ng Tsina hanggang sa restaurant.',
      phone: 'Numero ng Telepono',
      email: 'Email',
      getInTouch: 'Makipag-ugnay sa amin',
    }
  }
};

export default ph;